<template>
  <el-dialog
    :visible.sync="dialogVisible"
    center
    width="30%"
    :close-on-click-modal="false"
    :title="isEdit ? '编辑磅单' : '新增磅单'"
    @close="cancel"
  >
    <el-form
      size="small"
      label-width="100px"
      ref="formRef"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="司机手机号" prop="driverPhone">
        <el-input v-model="form.driverPhone" disabled></el-input>
      </el-form-item>
      <el-form-item label="车牌号" prop="plate">
        <el-input v-model="form.plate" disabled></el-input>
      </el-form-item>
      <el-form-item label="过重吨数" prop="roughWeight">
        <el-input v-model="form.roughWeight" @change="setSuttle"></el-input>
      </el-form-item>
      <el-form-item label="回皮吨数" prop="tare">
        <el-input v-model="form.tare" @change="setSuttle"></el-input>
      </el-form-item>
      <el-form-item label="装车吨数" prop="suttle">
        <el-input v-model="form.suttle" disabled></el-input>
      </el-form-item>
      <el-form-item label="过重时间" prop="mwitemdate">
        <el-date-picker
          style="width: 100%"
          type="datetime"
          v-model="form.mwitemdate"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <img-upload
          class="img-upload"
          title="上传附件"
          v-model="form.attachUrl"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="isEdit"
        class="buttons"
        size="small"
        type="primary"
        @click="confirmUpdate"
        >确 定</el-button
      >
      <el-button
        v-else
        class="buttons"
        size="small"
        type="primary"
        @click="confirmAdd"
        >确 定</el-button
      >
      <el-button class="buttons" size="small" @click="cancel">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import ImgUpload from "@/components/Img-upload.vue";
import Big from "big.js";
export default {
  data() {
    return {
      isEdit: false,
      dialogVisible: true,
      transportBillId: "",
      poundBillId: "",
      form: {
        driverPhone: "",
        plate: "",
        roughWeight: "",
        tare: "",
        suttle: "",
        mwitemdate: "",
        attachUrl: "",
      },
      rules: {
        driverPhone: [
          {
            required: true,
            message: "请输入司机手机号",
            trigger: ["blur", "change"],
          },
        ],
        plate: [
          {
            required: true,
            message: "请输入车牌号",
            trigger: ["blur", "change"],
          },
        ],
        roughWeight: [
          {
            required: true,
            message: "请输入过重吨数",
            trigger: ["blur", "change"],
          },
        ],
        tare: [
          {
            required: true,
            message: "请输入回皮吨数",
            trigger: ["blur", "change"],
          },
        ],
        mwitemdate: [
          {
            required: true,
            message: "请输入出场时间",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {
    ImgUpload,
  },
  methods: {
    setSuttle() {
      this.form.suttle = new Big(this.form.roughWeight)
        .minus(this.form.tare)
        .toString();
    },
    confirmAdd() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (!this.form.attachUrl) {
            return this.$message.error("请上传附件");
          }
          const { data } = await this.$http(
            "api1",
            "/api/biz/TransportBill/finish",
            "post",
            {
              transportBillId: this.transportBillId,
              poundBill: {
                ...this.form,
              },
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.$emit("confirm");
          }
        }
      });
    },
    confirmUpdate() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (!this.form.attachUrl) {
            return this.$message.error("请上传附件");
          }
          const { data } = await this.$http(
            "api1",
            "/api/biz/PoundBill/update",
            "post",
            {
              id: this.poundBillId,
              ...this.form,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.$emit("confirm");
          }
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
